
body{
    background: #f9fafc !important ;
}
@font-face {
    font-family: 'ProductSans-Bold';
    src: local('ProductSans-Bold'), url('fonts/ProductSans-Bold.ttf');
    
  }

/*
* Sidebar
*/
.sidebar {
    position: fixed !important;
    top: 0;
    z-index: 100; /*Behind the navbar*/
    padding: 55px 0 0 !important; /* Height of navbar */
}

.search-wrapper{
    position: relative;
    height: 55px;
    background-color: white;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-search {
    width: 95%;
    border-radius: 4px;
    border: 2px solid rgba(38, 38, 38, 0.415);
    padding: 3px 8px;
    margin: auto;
    transition: all 0.2s ease-in-out;
}

.sidebar-search:focus{
    border: 2px solid rgba(38, 38, 38, 0.506);
}

.search-cancel-icon{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
}

.update-template-sidebar {
    position: fixed !important;
    padding: 95px 0 0 !important;
}

.update-template-sidebar .sidebar-sticky {
    height: calc(100vh - 96px);
    overflow-x: auto;
}

.update-template-sidebar .nav-link {
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
}

.update-template-sidebar li {
    border-bottom: 1px solid #d3d3d3;
    margin-left: 34px;
    list-style: decimal;
}

@media (max-width: 767.98px) {
.sidebar {
    top: 5rem;
}
}

.sidebar-sticky {
top: 0;
height: calc(100vh - 55px);
overflow-x: hidden;
overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
background-color: white;
border-right: 1px solid #dedede;
box-shadow: 0 0 30px 0 rgb(200 200 200 / 20%);
z-index: 9;
transition: all 0.3s ease;
}

.sidebar-sticky svg{
    height: 20px;
    width: 20px;
}

.sidebar .nav-link, .tab-nav .nav-link {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    line-height: 1.5;
}

.sidebar .nav-link.active, .tab-nav .nav-link.active {
    color: #4c7cf3 !important;
}

.sidebar .nav-link:hover, .tab-nav .nav-link:hover {
    background: #dbe5fd;
    color: #4c7cf3;
}        

.container-fluid.collapse-icons-sidebar .sidebar {
    display: none !important;
}
.collapse-icons-sidebar main{
    max-width: calc(100% - 50px);
    flex: 0 0 calc(100% - 50px);
}
.collapse-icons-sidebar .mute-text {
    display: none;
}

/* 
* update tempate navbar
*/

.main-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 1;
    flex-direction: column;
    align-items: start;
}

.main-topbar .left-topbar,
.main-topbar .right-topbar {
    padding: 0px;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.right-topbar {
    margin-left: 16em !important;
    margin-top: 7px !important;
    background-color: white !important;
    width: 100% !important;
}

.left-topbar {
    margin: 0; border-bottom: solid 1px #bbbbbb; width: 100%; justify-content: start !important;
    background-color: #dbe5fd !important;
}

.main-topbar .left-topbar li , .main-topbar .right-topbar li {
    border-right: 2px solid rgba(0,0,0,.25);
    line-height: 1;
    font-size: 14px;
    overflow: hidden;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-topbar .left-topbar li:last-child,
.main-topbar .right-topbar li:last-child {
    border-right: none;
}

ul.right-topbar {
    border-bottom: solid 1px #e4e4e4;
    display: flex !important;
    justify-content: flex-start !important;
    margin-left: 0px !important;
    padding-left: 17.5% !important;
    margin-top: -1px !important;
}

.main-content-box {
    padding-top: 80px;
}

/* main-left-content */
.main-left-content.end-align-btn {
    text-align: end;
}
.main-left-content .card-settings {
    text-align: end;
}
.main-left-content .article-box , .main-left-content .section-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main-left-content .plus-button {
    border: none;
    background-color: transparent;
    color: black;
}
.main-left-content .plus-button:focus{
    box-shadow: none;
}
.main-left-content .plus-button span {
    color: #6c757d;
    font-size: 22px;
}

.disabled-link {
  pointer-events: none;
  color: grey;
}

/*
* Navbar
*/

.no-scroll {
    overflow: hidden;
   }
   
.navbar.bg-dark {
    background-color: #272B30 !important;
}

.navbar-brand {
    font-size: 1rem;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    min-height: 55px;
    max-height: 55px;
    display: flex !important;
    align-items: center;
}

.navbar .navbar-toggler {
top: .25rem;
right: 1rem;
}


/* Dropdown Button */
.dropdown-link {
font-weight: 500;
color: #333;
padding: 16px;
font-size: 16px;
border: none;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
display: none;
position: absolute;
background-color: #f9f9f9;
min-width: 200px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
}

.in-table {
    display: flex;
}

@media (max-width: 750.98px) {
.dropdown:hover .dropdown-content {
    left: 0;
    display: block; 
}
}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
display: block;
}
.card-header {
    background-color: #ffffff !important;
    color: #555555 !important;
}
.card {
    box-shadow: 0 0 30px 0 rgb(200 200 200 / 20%);
    border: 0.5px solid #0000001a !important;
}
.card-body .paragraph {
    padding: 15px 64px 0px;
}
.table thead th {
    border-bottom: 4px solid #dee2e6;
}

.table thead {
    border-bottom: 3px solid #dee2e6;
}

.form-control {
    font-size: 14px;
}

.form-group {
    z-index: 1;
    position: relative;
}

.whole-row {
    padding-bottom: 30px;
}

.modal .form-group {z-index: auto;}

.modal-dialog-scrollable .modal-content{
    overflow: inherit !important;
    max-height: 100vh !important;
}

.modal-fullscreen.modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh; /* Viewport height */
    display: flex;
    position: fixed;
    max-height: 100%;
}


.cross {
    transition: transform .3s ease-out;
}
.cross:hover {
    transform: scale(1.2);
}

.cross:focus {
box-shadow: none !important;
}

.card-footer button {
    border-radius: 30px;
}

.pre-wrap {
    min-width:350px;
    overflow: hidden;
    white-space: pre-line;
}

.table-loader{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #51515165;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-loader {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 10px;
}

.custom-loader div {
  width: 8%;
  height: 24%;
  background: rgb(0, 0, 0);
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  animation: fade 1s linear infinite;
}

.progress-loader {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #26242465;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading_text {
    color: black;
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
}

.loading {
    width: 150px;
    height: 10px;
    background-color: black;
    border-radius: 1em;
}

.main-loading {
    background-color: white;
    width: 500px;
    min-height: 100px;
    height: auto;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading_bar {
	position: relative;
	height: 10px;
	width: 30rem;
	background-color: rgb(181, 180, 180);
	border-radius: 1em;
	overflow: hidden;
    text-align: center;
}

.loading_bar::after {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	/* width: 80%; */
	height: 100%;
	/* background-color: rgba(230, 230, 230, 0.891); */
	background: linear-gradient(90deg, #fff5, rgba(230, 230, 230, 0.891));
	animation: loading-animation 1.3s infinite;
	border-radius: 1em;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.custom-loader .bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

.custom-loader .bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}

.custom-loader .bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}

.custom-loader .bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}

.custom-loader .bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}

.custom-loader .bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}

.custom-loader .bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}

.custom-loader .bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}

.custom-loader .bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}

.custom-loader .bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}

.custom-loader .bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}

.custom-loader .bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}

.modal-dialog.modal-lg{
    max-width: 842.5px;
}
.modal-dialog-scrollable .modal-body.resource {
    overflow: initial !important;
}

.version {
    display: inline-block;
    width: 100%;
    text-align: start;
    font-size: 12px;
    color:#999;
    margin-top: 9px;
}

.page-link {
    cursor: pointer !important;
    z-index: 0 !important;
    margin-left: 0px !important;
}

.col.date-picker {
    padding-left: inherit;
    padding-right: inherit;
}

.date-picker-inline {
    margin-left: 15px;
    margin-right: 15px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
background: transparent;
bottom: 0;
color: transparent;
cursor: pointer;
height: auto;
left: 0;
position: absolute;
right: 0;
top: 0;
width: auto;
}
.table-loader svg{
    text-align: center; 
    width: 100%;
    height: 100%;
}
.modal-loader svg{
    width: 100%;
    height: 100%;
}
.table-bordered, .table-bordered td, .table-bordered th{
    font-size: 14px;
}
.alert-table-task-p{
    margin-bottom: 0.2rem !important;
}
.react-select-border:focus{
    border-color: #80bdff !important;
    outline: 0 !important;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}

.checkbox-lg {
    width: 15px;
    height: 15px;
    margin-top: 1px;
}

#datePicker {
    z-index: 999;
}

input[type=date]::-webkit-inner-spin-button {
-webkit-appearance: none;
display: none;
}

.error-message {
font-size: 80%;
color: #dc3545;
}

/* Scroll the row */
.row .scroll { 
overflow-x: auto;
}

.reload-btn {
color: #555555 !important;
transform: perspective(1px) scale(1.1);    
filter: blur(0px);
    font-size: 10px;
    border-radius: 50% !important;
    transition: transform .3s ease-out !important;
position: relative;
top: 0px;
display: flex;
justify-content: center;
align-items: center;
}

.reload-btn:hover {
    transform: scale(1.3);
    background-color: #dbe5fd;
}

.card-header.inventory {
display: flex;
justify-content: flex-start;
}

.reload-btn:focus {
box-shadow: none !important;
}

.notification {
opacity: 1 !important; 
position: absolute;
top:45px;
right: 19px;
width:260px;
z-index: 99;
}

.toast-box {
background-color: rgba(255,255,255,.85);
}

.csv-link {
color: #007bff;
    text-decoration: none;
    background-color: transparent;
cursor: pointer;
}

.with-bootstrap .form-select, .with-bootstrap .form-control {
  display: inline-block !important; 
  width: auto !important;
}

.rule-remove, .ruleGroup-remove {
    float: right;
}

.with-bootstrap .form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: 0.875rem;
    padding-right: 2.25rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e');    
}

.ruleGroup-addRule, .rule-operators, .rule-fields, .rule-value, .ruleGroup-addGroup {
    margin-left: 0.5rem;
}

.ruleGroup-combinators:focus, .rule-fields:focus, .rule-operators:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

td a{
    text-decoration: underline;
}

.css-2b097c-container {
    z-index: auto;
}

.deed-filing .css-2b097c-container  {
    z-index: 1;
}

.deed-filed .css-2b097c-container  {
    z-index: 1;
}

.deed-filing {
    width: 120px;
}

.deed-filed {
    width: 95px;
}
.switch-help-text {
    margin-left: -35px;
}

.custom-input-row{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.mt-4.custom-search-checkboxes {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 16px;
    align-items: center;
}

.mt-4.custom-search-checkboxes > div {
    display: flex;
}

/* .view-lines.monaco-mouse-cursor-text {
    word-break: normal !important;
    white-space: normal !important;
    max-width: 580px !important;
    width:100% !important;
} */

.set-note {
    width: 100%;
    table-layout: fixed;
    min-width: 500px;
    display: table-cell;
    white-space: initial;
}

.wrap-cell {
    width: 100%;
    min-width:350px;
    overflow: hidden;
    white-space: normal;
}

.no-wrap * {
    white-space: normal;
}

.sc-bczRLJ.kKAPXk {
    justify-content: space-between;
}

.side-info .card.side-card {
    width: 100%;
    padding: 20px 24px;
    position: fixed!important;
    top: 185px!important;
    overflow: auto;
    right: 0;
    margin-right: 25px;
    max-width: 340px;
}

.side-info .more-info{
    display: none;
}

.side-info .tooltip {
  position: relative;
  display: inline-block;
  opacity: 1 !important;
  transform: translate(-50%, -50%);
    font-size: 20px;
    top: 50%;
    left: 50%;
}

.side-info .tooltip .tooltip-text {
    visibility: hidden;
    width: 320px;
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    padding: 10px 10px;
    position: absolute;
    z-index: 1;
    top: 90%;
    right: -640%;
    margin-left: -60px;
    box-shadow: 0 0 30px 0 rgb(200 200 200 / 20%) !important;
    border: 0.5px solid rgba(0,0,0,.10196078431372549)!important;
}

.side-info .tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.side-info .tooltip:hover .tooltip-text {
  visibility: visible;
}

.side-info table.table-sm td {
    font-size: 14px !important;
}

.side-info .info {
    font-size: 12px !important;
}

.side-info table.table-sm td h3 {
    margin: 5px;
    font-weight: 700;
    font-size: 12px
}
@media (max-width:1420px){
    .side-info table.table-sm td {
        font-size: 12px !important;
    }
    .side-info .card.side-card table td {
        padding: 0 0 0 0px;
    }   
    .side-info .card.side-card h3 {
        font-size: 13px;
    }
    .side-info table.table-sm td {
        font-size: 12px !important;
    }
    .side-info .card.side-card {
        max-width: 340px;
    }
}
    @media (max-width:1300px){
        .side-info .card.side-card{
            display: none;
        }

        .side-info .more-info{ 
            display: block;
            padding: 0px 10px;
            position: fixed!important;
            top: -85px!important;
            overflow: auto;
            right: -250px;
            margin-right: auto;
            overflow: hidden;
            width: 100%;
            max-width: 170px;
            max-width: 580px;
            height: 580px;
        }
}

rsw-ce {
    height: 376px !important;
    overflow: auto;
}

.link-download {
    text-decoration: none !important;
}

.row-hover:hover {
    background-color: #ececec;
}

.fix-cell {
    min-width: 300px;
    width: 300px;
}

.date-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.view-group {
    display: block !important;
}

.active-view {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
        /* color: #6c757d;
    border-color: #6c757d; */
}

.address-max-width {
    max-width: 20% !important;
}

.card-font span, .card-font label, .card-font a {
    font-size: 15px;
}

.small-button {
   padding: 0.05rem 0.37rem 0rem 0.37rem !important;
   font-size: 13px !important;
}

.tag-clr {
    display: inline-block !important;
    width: revert !important;
}

.popover-content {
    display: none;
    position: absolute;
    top: 150%;
    right: -10px;
    background-color: #ebebeb;
    padding: 15px;
    width: 350px;
    border-radius: 10px;
    z-index: 100;
}

.popover-content::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 5px;
    transform: translate(-50%, -50%);
    background-color: transparent;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #ebebeb;
}

.popover-btn {
    width: fit-content;
}

.popover-btn:hover .popover-content {
    display: block;
}

.custom-small-font {
    font-size: 11px;
}

.file-icon svg {
    width: 20px;
    height: 30px;
}

.image-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.drop-zone {
    width: 630px;
    height: 100px;
    border: 1px dashed black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.custom-file-input {
    display: none;
}

.drop-zone label:hover {
    background-color: #314d69;
}

.deed-image-main {
    margin: 15px;
}

.image-list {
    display: grid;
    grid-gap: 20px 10px;
    width: 630px;
    grid-template-columns: 1fr 1fr 1fr;
}
  
.image-item {
    position: relative;
    width: 200px;
    display: flex;
    justify-content: center;
    border: 1px solid #ebebeb;
    flex-direction: column;
    padding: 10px 20px;
    cursor:grab;
    background: #ffffff;
}
.upper-text label {
    margin: 0 !important;
    background: #8080804a;
    padding: 5px;
    font-size: 14px !important;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #8080804a;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical !important;
    min-height: 28px;
    line-height: 20px;
    height: 28px;
    display: -webkit-box;
    cursor: pointer;
}
.lower-text label {
    margin: 0 !important;
    background: #8080804a;
    padding: 5px;
    font-size: 14px !important;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #8080804a;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical !important;
    min-height: 28px;
    line-height: 20px;
    height: 28px;
    display: -webkit-box;
    cursor: pointer;
}

.image-meta {
    display: flex;
    align-items: center;
    margin-top: 7px;
    justify-content: space-between;
}

.image-meta .image-size {
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
    margin-top: 2px;
}

.image-meta .image-use span {
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
}


.image-meta .image-use .custom-control {
    padding-left: 0 !important;
}

.image-meta .image-use .custom-switch .custom-control-label::before {
    height: 16px;
}

.image-meta .image-use .custom-control-label:after, .custom-control-label:before {
  top: .30rem !important;
}

.image-meta .image-use .custom-control-input:checked ~ .custom-control-label::before {
    height: 16px;
}

.image-meta .image-use .custom-switch .custom-control-label::after {
    top: calc(0.35rem + 1px) !important;
}

.image-item .image-item-img {
    min-height: 130px;
    max-height: 130px;
    margin: 5px 0;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
}

.image-item .image-item-img img {
    width: 100%;
    height: 100%;
    max-height: 130px;
    min-height: 80px;
    object-fit: contain;
    -webkit-user-drag: none;
    -webkit-user-select: none !important;
}

.__react_modal_image__modal_content {
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
}
.__react_modal_image__medium_img {
    max-width: 70vw !important;
    max-height: 70vh !important;
    width: auto !important;
    height: auto !important;
}

.__react_modal_image__large_img {
    cursor: move !important;
    max-width: 100% !important;
    max-height: 100% !important;
}

.image-close-button span {
    background: gray;
    padding: 12px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #ffffff;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}

.after-text-added label {
    background: none !important;
    border: solid 1px hsla(0, 0%, 50.2%, .2901960784313726);
}

.drag-icon {
    position: absolute;
    top: 12px;
    left: 5px;
}
.drag-icon svg {
    height: 13px !important;
}
.drag-icon svg path {
    fill: #5b5959 !important;
}

.cursor-pointer {
    margin-top: 0;
    cursor: pointer;
}

.image-item .svg-pdf {
    width: 100%;
    height: 100%;
    max-height: 130px;
    min-height: 80px;
}

.image-icon {
    width: 100%;
    height: 100%;
    max-height: 10px;
    min-height: 10px;
}

.sticky-navbar {
    position: sticky;
    top: 41px;
    padding: 30px 0 0 0;
    z-index: 1;
    background: #f9fafc;
    margin-top: -30px;
}

.small-font {
    font-size: 0.9rem;
}
